import { useEffect, useMemo } from "react";
import { CheckboxElement, useForm } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import {
  ProfileConfigProperty,
  SensorProfileConfigType,
  SensorStatus,
} from "../../../../../../../../graphql/operations";
import { useAssetsDataContext } from "../../../../../AssetsDataContext/AssetsDataContext";
import { statusFilterSchema } from "./filter-helpers";

export interface StatusesCheckboxFilterGridProps {}

const DEFAULT_STATE = {
  healthy: undefined,
  warning: undefined,
  alert: undefined,
  critical: undefined,
};
export interface FormData {
  [key: string]: {
    healthy?: boolean;
    warning?: boolean;
    alert?: boolean;
    critical?: boolean;
  };
}

export const availableFilters = new Map<
  SensorProfileConfigType | ProfileConfigProperty,
  string
>([
  [SensorProfileConfigType.Airbag, "Air Bag"],
  [ProfileConfigProperty.TankPressure, "Air Tank"],
  [SensorProfileConfigType.AtisAlpha, "ATIS"],
  [SensorProfileConfigType.LiteSentryGamma, "Lights Out"],
  [ProfileConfigProperty.SupplyPressure, "Regulator"],
  [SensorProfileConfigType.TemperatureInternal, "Temperature"],
  [SensorProfileConfigType.TpmsBeta, "TPMS"],
  [SensorProfileConfigType.WheelEndTemperature, "Wheel End"],
  [SensorProfileConfigType.Battery, "Battery"],
  [SensorProfileConfigType.DualImbalance, "Dual Imbalance"],
  [SensorProfileConfigType.Liftgate, "Liftgate"],
]);

export const StatusesCheckboxFilterGrid: React.FC<
  StatusesCheckboxFilterGridProps
> = () => {
  const { onChangeFilters, currentFilter } = useAssetsDataContext();

  // Ingest current values for the checkboxes from global state
  const valuesFromStore = useMemo<FormData>(
    () =>
      Array.from(availableFilters.keys()).reduce((acc, sensorProperty) => {
        // Chosen (checked) statuses in currentFilter[sensor] are in an array like ["Healthy", "Warning", "Alert"]
        const filterValueArray = currentFilter[
          sensorProperty as keyof typeof currentFilter
        ] as SensorStatus[];

        // If there are no values we want to use "undefined" for each status
        if (!filterValueArray || isEmpty(filterValueArray))
          return {
            ...acc,
            [sensorProperty]: DEFAULT_STATE,
          };

        // If there are values -> transfer them into an object with the correct keys (the status names)
        const selectedStatuses = filterValueArray.reduce(
          (acc, status) => ({
            ...acc,
            [status.toLowerCase()]: true,
          }),
          DEFAULT_STATE
        );

        // Transfer into the parent object and move onto the next sensor
        return {
          ...acc,
          [sensorProperty]: selectedStatuses,
        };
      }, {}),
    [currentFilter]
  );

  const { control, trigger, getValues, setValue } = useForm<FormData>({
    resolver: yupResolver(statusFilterSchema),
    defaultValues: valuesFromStore,
  });

  // When values in store change -> Make sure to update the local form
  useEffect(() => {
    // For each sensor
    for (let key in valuesFromStore) {
      const currentValue = getValues(key);
      const incomingValue =
        valuesFromStore[key as keyof typeof valuesFromStore];

      // If they're different -> use the store value
      if (JSON.stringify(currentValue) !== JSON.stringify(incomingValue))
        setValue(key, { ...currentValue, ...incomingValue });
    }
  }, [valuesFromStore, getValues, setValue]);

  const handleCheckboxClick = async (sensorProperty: string) => {
    // Wait for form state to update
    await trigger(sensorProperty);

    const formData = getValues();
    const selectedFilter = formData[sensorProperty];

    const arrayFromFilterSelection = Object.entries(selectedFilter).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.push(key as never);
        }
        return acc;
      },
      []
    );

    const newFilters = {
      [sensorProperty]: arrayFromFilterSelection.length
        ? arrayFromFilterSelection
        : [],
    };

    onChangeFilters(newFilters);
  };

  return (
    <Box>
      <TableContainer
        component={Box}
        sx={{
          boxShadow: "none",
          border: "none",
          width: "fit-content",
        }}
      >
        <Table
          sx={{
            fontSize: "10px",
            "& th": {
              fontSize: "10px",
              backgroundColor: "unset",
            },
            "& td": {
              borderBottom: "1px solid var(--input-border-table-grid)",
            },
          }}
          stickyHeader
          aria-label="Sensor Statuses Filter"
        >
          <TableHead data-testid="asset-dashboard--statuses-checkbox-filter-grid-header">
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                sx={{
                  padding: 0,
                  width: "75px",
                  borderBottom: "none",
                  fontWeight: "700",
                }}
              >
                Sensors
              </TableCell>
              <TableCell
                sx={{
                  padding: "0 6px 0 0",
                  textAlign: "center",
                  borderBottom: "none",
                  width: "60px",
                  fontWeight: "700",
                }}
              >
                Healthy
              </TableCell>
              <TableCell
                sx={{
                  padding: "0 6px 0 6px",
                  textAlign: "center",
                  border: "1px solid var(--input-border-table-grid)",
                  borderTop: "none",
                  borderBottom: "none",
                  fontWeight: "700",
                }}
              >
                Warning
              </TableCell>
              <TableCell
                sx={{
                  padding: "0 6px 0 6px",
                  borderBottom: "none",
                  borderRight: "1px solid var(--input-border-table-grid)",
                  textAlign: "center",
                  fontWeight: "700",
                }}
              >
                Alert
              </TableCell>
              <TableCell
                sx={{
                  padding: "0 6px 0 6px",
                  borderBottom: "none",
                  textAlign: "center",
                  fontWeight: "700",
                }}
              >
                Critical
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="asset-dashboard--asset-dashboard--statuses-checkbox-filter-grid--body">
            {/* Sensor names & checkboxes by name */}
            {Array.from(availableFilters.entries()).map(
              ([sensorProperty, sensorLabel]: string[]) => (
                <TableRow
                  key={sensorLabel}
                  sx={{
                    padding: "0px",
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: 0,
                      borderBottom: "1px solid var(--input-border-table-grid)",
                    }}
                    component="th"
                    scope="row"
                  >
                    {sensorLabel}
                  </TableCell>
                  <TableCell sx={{ padding: 0 }}>
                    <CheckboxElement
                      data-testid={`${sensorProperty}.healthy`}
                      name={`${sensorProperty}.healthy` as never}
                      control={control}
                      sx={{
                        "&.Mui-checked": { color: "var(--success) !important" },
                      }}
                      onClick={() => handleCheckboxClick(sensorProperty)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 0 }}>
                    <CheckboxElement
                      data-testid={`${sensorProperty}.warning`}
                      name={`${sensorProperty}.warning` as never}
                      control={control}
                      sx={{
                        "&.Mui-checked": { color: "var(--caution) !important" },
                      }}
                      onClick={() => handleCheckboxClick(sensorProperty)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 0 }}>
                    <CheckboxElement
                      data-testid={`${sensorProperty}.alert`}
                      name={`${sensorProperty}.alert` as never}
                      control={control}
                      sx={{
                        "&.Mui-checked": { color: "var(--warning) !important" },
                      }}
                      onClick={() => handleCheckboxClick(sensorProperty)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 0 }}>
                    <CheckboxElement
                      data-testid={`${sensorProperty}.critical`}
                      name={`${sensorProperty}.critical` as never}
                      control={control}
                      sx={{
                        "&.Mui-checked": { color: "var(--error) !important" },
                      }}
                      onClick={() => handleCheckboxClick(sensorProperty)}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
