import {
  AssetInstallEvent,
  TableColumnFormat as ReportColumnFormat,
} from "../../../../../graphql/operations";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../../utils";
import {
  getAssetInstallStatusText,
  ReportGridColDef,
} from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  installDate: false,
  company: false,
  imei: false,
  vin: false,
  assetId: false,
  installerUserName: false,
  installStatus: false,
  location: false,
};

export const getColumns = (timezone: string): ReportGridColDef[] => [
  {
    field: "installDate",
    headerName: "Install Date",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.IsoDateTime,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "company",
    headerName: "Organization",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
  },
  {
    field: "assetId",
    headerName: "Asset ID",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.ImeiString,
    valueFormatter: imeiValueFormatter,
  },
  { field: "vin", headerName: "VIN", format: ReportColumnFormat.String },
  {
    field: "imei",
    headerName: "Device ID",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.ImeiString,
    valueFormatter: imeiValueFormatter,
  },
  {
    field: "installerUserName",
    headerName: "Installer",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
  {
    field: "installStatus",
    headerName: "Install Status",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    renderCell: (params) => getAssetInstallStatusText(params.value),
    filterable: false,
  },
  {
    field: "location",
    headerName: "Location",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.Location,
    groupable: false,
    valueGetter: (params) => {
      const { location } = params.row as AssetInstallEvent;
      return location?.coordinates
        ? `${location?.coordinates?.[1]}, ${location?.coordinates?.[0]}`
        : "";
    },
  },
];

export const SEARCH_KEYS = [
  "installDate",
  "company",
  "imei",
  "vin",
  "assetId",
  "installerUserName",
  "location",
];
